import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import Portfolio from '../Portfolio';
import CtaStyle2 from '../Cta/CtaStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const portfolioData = [
  {
    imgUrl: '/images/creative-agency/GrupoRemex.jpeg',
    title: 'Grupo Remex Construcciones',
  },
  {
    imgUrl: '/images/creative-agency/Gesco.jpeg',
    title: 'Gesco Contructora y Supervisora',
  },
  {
    imgUrl: '/images/creative-agency/Reddeplast.jpeg',
    title: 'Comercializadora Roddeplast',
  },
  {
    imgUrl: '/images/creative-agency/Socoe.jpeg',
    title: 'Constructora Socoe',
  },
  {
    imgUrl: '/images/creative-agency/Dch.jpeg',
    title: 'Diseño y Construcciones de Hidalgo',
  },
  {
    imgUrl: '/images/creative-agency/ap.jpeg',
    title: 'Constructora Soluciones A&P ',
  },
  {
    imgUrl: '/images/creative-agency/Dcs.jpeg',
    title: 'Diseño Construcción y Supervisión',
  },
  {
    imgUrl: '/images/creative-agency/Cigeh.jpeg',
    title: 'CIGEH',
  },
  
  {
    imgUrl: '/images/creative-agency/Avante.jpeg',
    title: 'Avante',
  },
  
  {
    imgUrl: '/images/creative-agency/Camarena.jpeg',
    title: 'Camarena',
  },
  {
    imgUrl: '/images/creative-agency/Hueyotli.jpeg',
    title: 'Hueyotli',
  },
  {
    imgUrl: '/images/creative-agency/Dch.jpeg',
    title: 'Dhc',
  },
  
];

export default function PortfolioPage() {
  pageTitle('Portfolio');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Hemos Desarrollado una Serie de Proyectos Destacados"
        subTitle="Nuestro Portafolio"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={portfolioData} />
        <div className="cs_height_75 cs_height_lg_40" />
        <CtaStyle2
          title="¿Estás listo para optimizar tu estrategia contable y fiscal?          "
          btnText="Contactar"
          btnUrl="/contact"
        />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </>
  );
}
