import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

const ConfirmationPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Icon icon="bi:check-circle-fill" style={{ fontSize: '48px', color: 'green' }} />
      <h1>Mensaje Enviado Exitosamente</h1>
      <p>Tu mensaje ha sido enviado, nos pondremos en contacto contigo pronto.</p>
      <button onClick={handleBack} style={{ cursor: 'pointer' }}>
        Regresar
      </button>
    </div>
  );
};

export default ConfirmationPage;
