import React, { useState } from 'react';
import Spacing from '../Spacing';
import parse from 'html-react-parser';

export default function VideoModal({ videoSrc, bgUrl, title, titleVariant }) {
  const [iframeSrc, setIframeSrc] = useState('about:blank');
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    setIframeSrc(`${videoSrc}`);
    setToggle(!toggle);
  };
  const handelClose = () => {
    setIframeSrc('about:blank');
    setToggle(!toggle);
  };

  return (
    <>
      {title && (
        <>
          <h2 className={`cs_video_block_1_title cs_fs_68 text-center mb-0 ${titleVariant || 'text-uppercase'}`}>
            {parse(title)}
          </h2>
          <Spacing lg="80" md="45" />
        </>
      )}
      <div
        className="cs_video_block cs_style_1 cs_bg_filed cs_radius_15 position-relative d-flex justify-content-center align-items-center overflow-hidden"
        style={{
          backgroundImage: `url(${bgUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '500px', // Ajusta esto según las necesidades de tu diseño
        }}
      >
        <span className="cs_hero_video_icon" onClick={handelClick} />
      </div>

      <div className={toggle ? 'cs_video_popup active' : 'cs_video_popup'}>
        <div className="cs_video_popup_overlay" />
        <div className="cs_video_popup_content">
          <div className="cs_video_popup_layer" />
          <div className="cs_video_popup_container">
            <div className="cs_video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={iframeSrc}
                  title="video modal"
                  allowFullScreen
                />
              </div>
            </div>
            <div className="cs_video_popup_close" onClick={handelClose} />
          </div>
        </div>
      </div>
    </>
  );
}
