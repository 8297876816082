import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const addressDataList = [
  'Av. de las Flores 610, Tulipanes I,',
  '42185 Mineral de la Reforma, Hgo.',
  '771 675 4107',
  '771 189 7913',
  'lcgachuzcontacto@ecoestrategia.com.mx',
];
const titleStyle = {
  color: '#71CE8A', // Cambia 'white' por cualquier color en código hexadecimal o nombre de color CSS si es necesario
};

const LinksMenuList = [
  {
    title: 'Inicio',
    href: '/',
  },
  {
    title: 'Acerca de ',
    href: '/about',
  },
  {
    title: 'Servicios',
    href: '/service',
  },
  {
    title: 'Portafolio',
    href: '/portfolio',
  },
  {
    title: 'Contacto',
    href: '/contact',
  },
];

const socialBtnList = [
  {
    icon: 'fa6-brands:instagram',
    href: 'https://www.instagram.com/eco_estrategia/',
  },
  {
    icon: 'fa6-brands:facebook-f',
    href: 'https://www.facebook.com/profile.php?id=61552142725770',
  },
];

export default function Footer() {
  return (
    <footer
      className="cs_footer cs_bg_filed"
      style={{ backgroundColor: '#fff', marginTop: '50px'}}
    >
      <div className="container">
        <div className="row">
          {/* Columna 1: Logo y espacio para párrafo */}
          <div className="col-lg-4 col-sm-12">
            <div className="cs_footer_item">
              <img
                src="/images/LOGO_ECOPNG_4x-8-removebg-preview.png"
                style={{ maxWidth: '30%' }}
                alt="Logo"
              />
              <p>Comprometidos con la excelencia en contabilidad y asesoría fiscal. Nuestra misión es empoderar a las empresas a través de un manejo financiero estratégico y responsable.</p>
            </div>
          </div>
          
          {/* Columna 2: Contacto */}
          <div className="col-lg-4 col-sm-12">
            <div className="cs_footer_item">
              <h2 className="cs_widget_title" style={titleStyle}>Contacto</h2>
              <ul className="cs_menu_widget cs_mp0">
                {addressDataList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          
          {/* Columna 3: Secciones */}
          <div className="col-lg-4 col-sm-12">
            <div className="cs_footer_item">
              <h2 className="cs_widget_title"style={titleStyle} >Secciones</h2>
              <ul className="cs_menu_widget cs_mp0">
                {LinksMenuList.map((item, index) => (
                  <li key={index}>
                    <Link to={item.href}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_bottom_footer " style={{ backgroundColor: '#0A2C6B'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="cs_social_btns cs_style_1">
                {socialBtnList.map((item, index) => (
                  <Link key={index} to={item.href} className="cs_center">
                    <Icon icon={item.icon} />
                  </Link>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end" >
              <p>Derechos Reservados © 2024 Eco Estrategia.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
