import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import ServiceStyle2 from '../Service/ServiceStyle2';
import CtaStyle2 from '../Cta/CtaStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const serviceData = [
  {
    number: '01',
    title: 'Contabilidad Integral',
    subTitle:
      'Registro sistemático y elaboración de estados financieros para personas físicas y morales de todos los regímenes fiscales. Nuestra meticulosa atención al detalle asegura una contabilidad impecable y una visión clara de la salud financiera de tu empresa.',
    thumbnailSrc: '/images/studio-agency/service1.jpg',
  },
  {
    number: '02',
    title: 'Gestión de Impuestos',
    subTitle:
      'Determinación exacta y oportuna de impuestos federales y estatales. Nos especializamos en IVA, ISR e ISN, garantizando cumplimiento sin errores y optimización fiscal.',
    thumbnailSrc: '/images/studio-agency/service2.jpg',
  },
  {
    number: '03',
    title: 'Timbrado de Nómina',
    subTitle:
      'Servicio completo de nómina que incluye la elaboración de listas de raya y el timbrado de recibos electrónicos ante el SAT, asegurando que cada detalle cumpla con la normativa vigente.',
    thumbnailSrc: '/images/studio-agency/service3.jpg',
  },
  {
    number: '04',
    title: 'Auditorías',
    subTitle:
      'Realizamos auditorías internas y externas exhaustivas, verificando la correcta administración de procesos y la determinación de cuotas IMSS e impuestos federales, con recomendaciones estratégicas para mejorar la eficiencia operativa.',
    thumbnailSrc: '/images/studio-agency/service4.jpg',
  },
  {
    number: '05',
    title: 'Vigilancia SAT',
    subTitle:
      'Especialistas en el seguimiento de revisiones profundas por parte del SAT, solucionamos observaciones y elaboramos la documentación necesaria para la solicitud de saldos a favor de IVA y ISR.',
    thumbnailSrc: '/images/studio-agency/service5.jpg',
    href: '/service',
  },
  {
    number: '06',
    title: 'Cumplimiento Regulatorio',
    subTitle:
      'Nos encargamos de la presentación de avisos en plataformas como SISUB, ICSOE, y registros de obra IMSS, garantizando que toda la información necesaria se comunique correctamente a las instituciones gubernamentales.',
    thumbnailSrc: '/images/studio-agency/service6.jpg',
    href: '/service',
  },
  {
    number: '07',
    title: 'Constitución de Sociedades',
    subTitle:
      'Brindamos asesoría y acompañamiento en el proceso de constitución de sociedades mercantiles, incluyendo el trámite para la obtención del RFC ante el SAT.',
    thumbnailSrc: '/images/studio-agency/service7.jpg',
    href: '/service',
  },
  {
    number: '08',
    title: 'Servicios de Facturación',
    subTitle:
      'Apoyamos a nuestros clientes en la emisión correcta de facturas, asegurando que cada documento cumpla con las disposiciones legales y fiscales.',
    thumbnailSrc: '/images/studio-agency/service8.jpg',
    href: '/service',
  },
  {
    number: '09',
    title: 'Inscripción en Padrón Estatal',
    subTitle:
      'Facilitamos la inscripción en el padrón estatal de contribuyentes, así como el trámite de padrón de contratistas y proveedores, recabando y organizando todos los documentos necesarios.',
    thumbnailSrc: '/images/studio-agency/service9.jpg',
    href: '/service',
  },
];

export default function ServicePage() {
  pageTitle('Service');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Servicios contables y fiscales integrales para personas físicas y morales. "
        subTitle="Nuestros Servicios"
        shape="shape_2"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <ServiceStyle2 data={serviceData} />
      </div>
      <Spacing lg="150" md="80" />
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="¿Listo para transformar tu gestión contable y fiscal?"
        subTitle="Conecta con nuestro equipo y da el primer paso hacia una estrategia financiera exitosa."
        btnText="Solicitar Información"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
