import React, { useRef }from 'react';
import { useNavigate } from 'react-router-dom';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';
import emailjs from 'emailjs-com';


export default function ContactPage() {
  pageTitle('Contact');
  const form = useRef();
  const navigate = useNavigate();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3lx33af', 'template_18wim33', form.current, 'WF7zlxidEqvLlBwue')
      .then((result) => {
          console.log(result.text);
          navigate('/confirmacion');

          // Aquí puedes agregar lógica para mostrar un mensaje de éxito
      }, (error) => {
          console.log(error.text);
          // Aquí puedes agregar lógica para manejar errores
      });
  };
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Conecta con Eco Estrategia para Servicios Contables y Fiscales"
        subTitle="Contacto"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">
              ¡Visítanos y comienza a <br />
              transformar tu estrategia financiera!
              </h2>
              <div className="cs_height_55 cs_height_lg_30" />
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0">lcgachuzcontacto@ecoestrategia.com.mx</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Teléfono:</h3>
                  <p className="mb-0">771 675 4107</p>
                  <p className="mb-0">771 189 7913</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Dirección:</h3>
                  <p className="mb-0">Av. de las Flores 610, Tulipanes I, <br/> 42185 Mineral de la Reforma, Hgo.</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form" ref={form} onSubmit={sendEmail}>
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Tu nombre completo
                  </label>
                  <input
                    placeholder="Escribe tu nombre"
                    type="text"
                    name="nombre"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Correo electrónico
                  </label>
                  <input
                    placeholder="Escribe tu dirección de correo electrónico"
                    type="text"
                    name="email"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                  Número de teléfono
                  </label>
                  <input
                    placeholder="Escribe tu número de teléfono"
                    type="text"
                    name="telefono"
                    className="cs_form_field"
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Mensaje
                  </label>
                  <input
                    placeholder="Escribe tu mensaje "
                    type="text"
                    name="mensaje"
                    className="cs_form_field"
                  />
                  <textarea cols={30} rows={5} className="cs_form_field" />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button className="cs_btn cs_style_1" type="submit">
                    Contáctanos{' '}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
    </>
  );
  
}