import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { Icon } from '@iconify/react';
import { pageTitle } from '../../helpers/PageTitle';

export default function PortfolioDetailsPage() {
  pageTitle('Portfolio Details');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Portafolio de Éxito en Eco Estrategia"
        subTitle="Portafolio"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src="/images/others/portfolio_details_1.jpeg" alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <ul className="cs_portfolio_details_info cs_mp0">
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Cliente:</h3>
                <p className="mb-0">Andrew romania</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Ubicacion:</h3>
                <p className="mb-0">United Kindom</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Presupuesto:</h3>
                <p className="mb-0">$2500</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  Proyecto completado:
                </h3>
                <p className="mb-0">11 Agosto,2022</p>
              </li>
            </ul>
            <div className="cs_portfolio_details_right">
              <h2>Resumen del Proyecto</h2>
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut enim quis augue luctus viverra. Fusce placerat sit amet orci eu malesuada. Vivamus tristique id dolor bibendum luctus. Suspendisse potenti. Cras facilisis ante vel congue iaculis. Nulla cursus ullamcorper ipsum, vel egestas elit pulvinar sed. Donec et interdum felis. Donec ultrices sapien neque, sit amet maximus nunc elementum nec. Mauris viverra dignissim vestibulum. Nullam condimentum odio nec ante euismod.

                <br />
              </p>
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut enim quis augue luctus viverra. Fusce placerat sit amet orci eu malesuada. Vivamus tristique id dolor bibendum luctus. Suspendisse potenti. Cras facilisis ante vel congue iaculis. Nulla cursus ullamcorper ipsum, vel egestas elit pulvinar sed. Donec et interdum felis. Donec ultrices sapien neque, sit amet maximus nunc elementum nec. Mauris viverra dignissim vestibulum. Nullam condimentum odio nec ante euismod.


              </p>
            </div>
          </div>
          <Spacing lg="115" md="60" />
          
        </div>
        <Spacing lg="90" md="60" />
        <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
    </>
  );
}
