import React from 'react';
import Hero from '../Hero';
import Spacing from '../Spacing';
import About from '../About';
import WhyChose from '../WhyChose';
import Service from '../Service';
import Portfolio from '../Portfolio';
import Button from '../Button';
import SectionHeading from '../SectionHeading';
import Cta from '../Cta';
import TestimonialSlider from '../Slider/TestimonialSlider';
import { pageTitle } from '../../helpers/PageTitle';


const whyChoseFeatureData = [
  {
    title: 'Equipo Altamente Calificado y Profesional',
    content:
      'Nuestros expertos en contabilidad y fiscalidad se comprometen con la precisión y la excelencia, proporcionando estrategias personalizadas que se alinean con las metas y desafíos únicos de tu empresa.',
  },
  {
    title: 'Sistemas Avanzados para la Contabilidad',
    content:
      'Utilizamos tecnología de vanguardia para asegurar un registro contable impecable y una planificación fiscal que maximice tus beneficios y eficiencia.',
  },
  {
    title: 'Fórmulas de Éxito Probadas en Estrategias Fiscales',
    content:
      'Nuestro historial de éxito se basa en enfoques probados y personalizados que resultan en soluciones fiscales y contables óptimas para cada cliente.',
  },
];
const serviceListData = [
  {
    title: 'Contabilidad Integral',
    subtitle:
      'Registro sistemático y elaboración de estados financieros para personas físicas y morales de todos los regímenes fiscales. Nuestra meticulosa atención al detalle asegura una contabilidad impecable y una visión clara de la salud financiera de tu empresa.',
    imgUrl: '/images/creative-agency/service.jpg',
    href: '/service',
  },
  {
    title: 'Gestión de Impuestos',
    subtitle:
      'Determinación exacta y oportuna de impuestos federales y estatales. Nos especializamos en IVA, ISR e ISN, garantizando cumplimiento sin errores y optimización fiscal.',
    imgUrl: '/images/creative-agency/service_8.jpg',
    href: '/service',
  },
  {
    title: 'Timbrado de Nómina',
    subtitle:
      'Servicio completo de nómina que incluye la elaboración de listas de raya y el timbrado de recibos electrónicos ante el SAT, asegurando que cada detalle cumpla con la normativa vigente.',
    imgUrl: '/images/creative-agency/service.jpg',
    href: '/service',
  },
  {
    title: 'Auditorías',
    subtitle:
      'Realizamos auditorías internas y externas exhaustivas, verificando la correcta administración de procesos y la determinación de cuotas IMSS e impuestos federales, con recomendaciones estratégicas para mejorar la eficiencia operativa.',
    imgUrl: '/images/creative-agency/service_8.jpg',
    href: '/service',
  },
];

const portfolioData = [
  
  {
    imgUrl: '/images/creative-agency/Gesco.jpeg',
    title: 'Gesco',
  },
  
  {
    imgUrl: '/images/creative-agency/GrupoRemex.jpeg',
    title: 'Grupo Remex',
  },
  {
    imgUrl: '/images/creative-agency/Socoe.jpeg',
    title: 'Constructora Socoe',
  },
];

const testimonialData = [
  {
    text: 'Eco Estrategia ha sido un socio fundamental en la reestructuración financiera de nuestra compañía. Su profundo conocimiento en contabilidad y fiscalidad ha mejorado significativamente nuestra gestión interna y cumplimiento de obligaciones fiscales. Recomendamos ampliamente su servicio detallado y su compromiso con la excelencia.',
  
    
  },
  {
    text: 'Eco Estrategia ha sido un socio fundamental en la reestructuración financiera de nuestra compañía. Su profundo conocimiento en contabilidad y fiscalidad ha mejorado significativamente nuestra gestión interna y cumplimiento de obligaciones fiscales. Recomendamos ampliamente su servicio detallado y su compromiso con la excelencia.',
    
  },
  {
    text: 'Eco Estrategia ha sido un socio fundamental en la reestructuración financiera de nuestra compañía. Su profundo conocimiento en contabilidad y fiscalidad ha mejorado significativamente nuestra gestión interna y cumplimiento de obligaciones fiscales. Recomendamos ampliamente su servicio detallado y su compromiso con la excelencia',
   
  },
];

const titleStyle = {
  color: '#71CE8A', 
  padding: '0px',// Cambia 'white' por cualquier color en código hexadecimal o nombre de color CSS si es necesario
};
const postData = [
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How to keep fear from ruining your art business with confident',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Artistic mind will be great for creation anything',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'AI will take over all job for human within few years',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Your agency need to replace some artistic mind people',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How to keep fear from ruining your art business with confident',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Artistic mind will be great for creation anything',
    date: '22 Apr 2023',
    url: '/portfolio/portfolio-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'AI will take over all job for human within few years',
    date: '13 May 2023',
    url: '/portfolio/portfolio-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Your agency need to replace some artistic mind people',
    date: '15 Mar 2023',
    url: '/portfolio/portfolio-details',
  },
];

export default function Home() {
  pageTitle('Eco Estrategia');
  return (
    <>
    
            <Spacing lg="0" md="0" />

      <img src="/images/creative-agency/collage_Mesade trabajo1.png" alt="Logo Ecoestrategia" />
      
      <Spacing lg="50" md="70" />
      <About style={titleStyle } 
        thumbnail="/images/creative-agency/about.jpg"
        uperTitle="Quiénes Somos"
        title="Eco Estrategia: Despacho Contable"
        subTitle="Nos distinguimos por resolver con transparencia y diligencia las necesidades fiscales, contables, financieras y administrativas. Nuestro compromiso es impulsar el progreso y la estabilidad de las PYMES y grandes empresas."
        featureList={[
          'Contabilidad Especializada',
          'Servicios Fiscales y Financieros',
          'Auditoría y Cumplimiento',
          'Enfoque Personalizado',
        ]}
        btnText="Vámos"
        btnUrl="/about"
      />
      <Spacing lg="100" md="75" />
      <WhyChose
        sectionTitle="Profundidad de Conocimiento en Servicios Contables y Fiscales"
        sectionSubTitle="Por Qué Elegirnos"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/creative-agency/why_choose_us_img_3.jpg"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <Spacing lg="50" md="75" />
        <div className="container">
          <SectionHeading
            title="Nuestros Servicios Principales"
            subTitle="Servicios"
            variantColor="cs_white_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <section>
        <Spacing lg="50" md="75" />
        <div className="container">
          <SectionHeading title="Algunos de nuestros clientes" subTitle="Portafolio" />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText="Ver todos los proyectos" btnUrl="/portfolio" />
          </div>
        </div>
        <Spacing lg="10" md="80" />
      </section>
      <TestimonialSlider 
        layeredImages={[
          '/images/creative-agency/IMG_8066.jpg',
        ]}
        data={testimonialData}
        textStyle={{ color: 'white' }}
      />
      <section>
        <div className="container">
          <Cta
            title="Hablemos y diseñemos juntos la ruta hacia tu éxito financiero."
            btnText="Contáctanos"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
    </>
  );
}
